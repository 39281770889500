import { ReactElement, ReactNode, useId, useMemo, useState } from 'react'
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Checkbox,
  FormControl,
  FormLabel,
  Heading,
  Skeleton,
  Spinner,
  Tag,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import { MultiValue, SingleValue } from 'chakra-react-select'
import { format, setMonth, setYear } from 'date-fns'

import { getMinutesToTime, useWorkLog, WorkLog, WorkLogMonthSlider } from '@/features/work-log'
import { useAvailableProjectList } from '@/features/project'
import { useFilteredUserList, useUserList } from '@/features/user'
import {
  ArchiveApprovalModal,
  CreateRejectCommentForm,
  RejectCommentModal,
  useCreateApproval,
} from '@/features/approval'
import { AvailableProject } from '@/entity/project'
import { IdentifiableWithDisplayName } from '@/shared/model'
import { Select, toast } from '@/shared/ui-kit'

interface ApprovalGroup {
  [projectName: string]: WorkLog[]
}

interface UserGroup {
  [userId: string]: string
}

interface ApprovalCheckedGroup {
  [projectName: string]: Set<string>
}

const getFormattedTime = (date: string): string => format(new Date(date), 'dd.MM EEEEEE')

// TODO: Отрефакторить методы по апруву часов
export const ApprovalPage = (): ReactElement => {
  const rejectModal = useDisclosure()
  const archiveModal = useDisclosure()

  const [userValue, setUserValue] = useState<IdentifiableWithDisplayName | null>(null)
  const [userInputValue, setUserInputValue] = useState<string>('')

  const [projectValue, setProjectValue] = useState<AvailableProject | null>(null)
  const [projectInputValue, setProjectInputValue] = useState<string>('')

  const [checkedLogs, setCheckedLogs] = useState<ApprovalCheckedGroup>({})
  const [currentViewDate, setCurrentViewDate] = useState<Date>(new Date())
  const [selectedWorkLog, setSelectedWorkLog] = useState<WorkLog | null>(null)

  const projectId = useId()
  const employeeId = useId()

  const userList = useUserList()
  const availableProjectList = useAvailableProjectList()
  const workLog = useWorkLog({
    currentViewDate,
    projectId: projectValue?.id,
    userId: userValue?.id,
  })
  const filteredUserList = useFilteredUserList({
    name: userInputValue,
    workLogRequired: true,
  })
  const createApproval = useCreateApproval()

  const handleProjectChange = (newValue: SingleValue<AvailableProject> | MultiValue<AvailableProject>): void => {
    if (!Array.isArray(newValue)) {
      setProjectValue(newValue as AvailableProject)
    }
  }

  const handleUserChange = (
    newValue: SingleValue<IdentifiableWithDisplayName> | MultiValue<IdentifiableWithDisplayName>,
  ): void => {
    if (!Array.isArray(newValue)) {
      setUserValue(newValue as SingleValue<IdentifiableWithDisplayName>)
    }
  }

  const handleProjectInputChange = (inputText: string): void => {
    setProjectInputValue(inputText)
  }

  const handleUserInputChange = (inputText: string): void => {
    setUserInputValue(inputText)
  }

  const handleMonthChange = (selectedMonth: number, selectedYear?: number): void => {
    const selectedYearDate = setYear(currentViewDate, selectedYear || currentViewDate.getFullYear())
    const selectedMonthDate = setMonth(selectedYearDate, selectedMonth)
    const currentMonth = currentViewDate.getMonth()

    if (currentMonth !== selectedMonth) {
      setCurrentViewDate(selectedMonthDate)
    }
  }

  const userGroup = useMemo((): UserGroup => {
    const userGroup = {} as UserGroup
    if (!userList.data) {
      return userGroup
    }

    return userList.data.reduce(
      (result, currentUser) => ({
        ...result,
        [currentUser.id]: `${currentUser.firstName} ${currentUser.lastName}`,
      }),
      userGroup,
    )
  }, [userList.data])

  const approvalGroup = useMemo((): ApprovalGroup => {
    const approvalGroup = {} as ApprovalGroup
    if (!workLog.data?.hashmap) {
      return approvalGroup
    }

    const values = Array.from(workLog.data?.hashmap?.values() ?? [])
    return values
      .filter((workLog) => !!workLog.logs.length)
      .reduce((result, { logs }) => {
        logs.forEach((log) => {
          if (log.approval?.status === 'PENDING') {
            const projectName = log.project.name
            if (!result[projectName]) {
              result[projectName] = []
            }

            result[projectName].push(log)
          }
        })

        return result
      }, {} as ApprovalGroup)
  }, [workLog.data?.hashmap])

  const checkedLogsCount = useMemo(
    (): number => Object.values(checkedLogs).reduce((result, checkedLogsSet) => result + checkedLogsSet.size, 0),
    [checkedLogs],
  )

  const handleApproveSubmit = (loggedWorkId?: string): void => {
    const massLogs = Object.values(checkedLogs).flatMap((checkedLogsSet) => Array.from(checkedLogsSet))
    const loggedWorkIds = loggedWorkId ? [loggedWorkId] : massLogs

    const isMassApprove = !loggedWorkId
    const toastTitle = isMassApprove
      ? `Записи (${checkedLogsCount}) успешно подтверждены`
      : 'Запись успешно подтверждена'

    createApproval.mutate(
      {
        loggedWorkIds,
        approved: true,
        comment: '',
      },
      {
        onSuccess: () => {
          workLog.refetch()
          toast({
            title: toastTitle,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })

          if (isMassApprove) {
            setCheckedLogs({})
          }
        },
      },
    )
  }

  const handleRejectModalOpen = (workLog: WorkLog): void => {
    setSelectedWorkLog(workLog)
    rejectModal.onOpen()
  }

  const handleRejectModalClose = (): void => {
    rejectModal.onClose()
    setSelectedWorkLog(null)
  }

  const handleArchiveModalClose = (): void => {
    archiveModal.onClose()
    setSelectedWorkLog(null)
  }

  const handleArchiveModalSubmit = (): void => {
    workLog.refetch()
    handleArchiveModalClose()
  }

  const handleRejectSubmit = ({ comment }: CreateRejectCommentForm): void => {
    rejectModal.onClose()
    if (!selectedWorkLog) {
      return
    }

    createApproval.mutate(
      {
        loggedWorkIds: [selectedWorkLog.id],
        approved: false,
        comment,
      },
      {
        onSuccess: () => {
          workLog.refetch()
          toast({
            title: `Запись успешно отклонена!`,
            status: 'success',
            duration: 3000,
            isClosable: true,
          })
          setSelectedWorkLog(null)
        },
      },
    )
  }

  const isGroupChecked = (projectName: string): boolean =>
    checkedLogs[projectName]?.size === approvalGroup[projectName].length

  const handleApprovalGroupCheck = (projectName: string): void => {
    const updatedLogs = { ...checkedLogs }

    if (isGroupChecked(projectName)) {
      delete updatedLogs[projectName]
    } else {
      if (!updatedLogs[projectName]) {
        updatedLogs[projectName] = new Set()
      }

      approvalGroup[projectName].forEach((workLog) => {
        if (!updatedLogs[projectName].has(workLog.id)) {
          updatedLogs[projectName].add(workLog.id)
        }
      })
    }

    setCheckedLogs(updatedLogs)
  }

  const handleApprovalCheck = (workLogId: string, projectName: string): void => {
    const updatedLogs = { ...checkedLogs }

    if (!updatedLogs[projectName]) {
      updatedLogs[projectName] = new Set()
    }

    if (updatedLogs[projectName].has(workLogId)) {
      updatedLogs[projectName].delete(workLogId)

      if (!updatedLogs[projectName].size) {
        delete updatedLogs[projectName]
      }
    } else {
      updatedLogs[projectName].add(workLogId)
    }

    setCheckedLogs(updatedLogs)
  }

  const renderContent = (): ReactNode => {
    if (workLog.isLoading) {
      return (
        <Center pt={4}>
          <Spinner />
        </Center>
      )
    }

    const approvalKeys = Object.keys(approvalGroup)
    if (approvalKeys.length) {
      return approvalKeys.map((projectName, index) => (
        <Box display="flex" flexDir="column" key={projectName} pt={index == 0 ? 4 : 0}>
          <Box display="flex" alignItems="center" columnGap={2}>
            <Checkbox
              width="20px"
              isIndeterminate={isGroupChecked(projectName)}
              onChange={() => handleApprovalGroupCheck(projectName)}
            />
            <Heading size="md">{projectName}</Heading>
            <Tag size="md" colorScheme="teal">
              {approvalGroup[projectName].length}
            </Tag>
          </Box>

          <Box display="flex" alignItems="center" columnGap={4} borderBottom="1px solid" borderColor="gray.500" py={2}>
            <Box width="20px" />
            <Text fontSize="sm" color="gray.400" width="15%">
              Сотрудник
            </Text>
            <Text fontSize="sm" color="gray.400" width="160px">
              Дата
            </Text>
            <Text fontSize="sm" color="gray.400" width="90px">
              Время
            </Text>
            <Text fontSize="sm" color="gray.400" width="90px">
              Описание
            </Text>
          </Box>

          {approvalGroup[projectName].map((approvalLog) => {
            const userName = approvalLog.userId ? userGroup[approvalLog.userId] : '-'
            const isLogChecked = checkedLogs[projectName]?.has(approvalLog.id) ?? false

            return (
              <Box
                key={approvalLog.id}
                display="flex"
                alignItems="center"
                textAlign="left"
                justifyContent="space-between"
                columnGap={4}
                py={3}
                borderBottom="1px solid"
                borderColor="gray.700"
              >
                <Checkbox
                  width="20px"
                  isChecked={isLogChecked}
                  onChange={() => handleApprovalCheck(approvalLog.id, projectName)}
                />
                <Text width="15%">{userName}</Text>
                <Text width="160px">{getFormattedTime(approvalLog.date)}</Text>
                <Text width="90px">{getMinutesToTime(approvalLog.workDuration)}</Text>
                <Text>{approvalLog.description}</Text>

                <Box display="flex" columnGap={4} ml="auto">
                  <Button colorScheme="teal" onClick={() => handleApproveSubmit(approvalLog.id)}>
                    Одобрить
                  </Button>

                  <Button variant="outline" onClick={() => handleRejectModalOpen(approvalLog)}>
                    Отклонить
                  </Button>
                </Box>
              </Box>
            )
          })}
        </Box>
      ))
    }

    return (
      <Alert status="info">
        <AlertIcon />
        <Text>Активностей нет</Text>
      </Alert>
    )
  }

  const isSomeLogsChecked = checkedLogsCount > 0
  const massActionButtonHint = isSomeLogsChecked ? `(${checkedLogsCount})` : ''

  return (
    <Box display="flex" flexDir="column" rowGap={4}>
      <Box display="flex" justifyContent="space-between">
        <WorkLogMonthSlider currentDate={currentViewDate} onMonthChange={handleMonthChange} />

        <Box display="flex" columnGap={4}>
          <Button colorScheme="teal" isDisabled={!isSomeLogsChecked} onClick={() => handleApproveSubmit()}>
            Массовое одобрение {massActionButtonHint}
          </Button>
        </Box>
      </Box>

      <Box display="flex" alignItems="center" columnGap={4}>
        <FormControl isRequired maxWidth="320px">
          <Skeleton isLoaded={!availableProjectList.isLoading}>
            <FormLabel htmlFor={projectId}>Проект</FormLabel>
          </Skeleton>

          <Skeleton isLoaded={!availableProjectList.isLoading}>
            <Select
              isClearable
              inputId={projectId}
              placeholder="Все проекты"
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              isLoading={availableProjectList.isLoading}
              options={availableProjectList.data}
              inputValue={projectInputValue}
              onInputChange={handleProjectInputChange}
              onChange={handleProjectChange}
            />
          </Skeleton>
        </FormControl>

        <FormControl maxWidth="320px">
          <Skeleton isLoaded={!availableProjectList.isLoading}>
            <FormLabel htmlFor={projectId}>Сотрудник</FormLabel>
          </Skeleton>

          <Skeleton isLoaded={!availableProjectList.isLoading}>
            <Select
              isClearable
              inputId={employeeId}
              filterOption={null}
              placeholder="Введите имя сотрудника..."
              noOptionsText="Данный сотрудник отсутствует"
              loadingMessage={() => 'Загрузка...'}
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.displayName}
              isLoading={filteredUserList.isLoading}
              options={filteredUserList.data}
              inputValue={userInputValue}
              onInputChange={handleUserInputChange}
              onChange={handleUserChange}
            />
          </Skeleton>
        </FormControl>
      </Box>

      <Box display="flex" flexDir="column" rowGap={4}>
        {renderContent()}
      </Box>

      <RejectCommentModal isOpen={rejectModal.isOpen} onClose={handleRejectModalClose} onSubmit={handleRejectSubmit} />

      <ArchiveApprovalModal
        isOpen={archiveModal.isOpen}
        workLogToArchive={selectedWorkLog}
        onClose={handleArchiveModalClose}
        onSuccess={handleArchiveModalSubmit}
      />
    </Box>
  )
}
