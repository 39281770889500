import { ReactElement } from 'react'
import { Box, Button, ButtonGroup, Grid, GridItem, IconButton, useDisclosure } from '@chakra-ui/react'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import { format } from 'date-fns'

import { Overlay } from '@/shared/ui-kit'

const FIRST_MONTH = 0
const TOTAL_MONTH_NUMBER = 12

const getMonthName = (monthNumber: number, formatStr = 'LLL'): string =>
  format(new Date(new Date().getFullYear(), monthNumber, 1), formatStr)

interface WorkLogMonthSliderProps {
  currentDate: Date
  onMonthChange: (selectedMonth: number, selectedYear?: number) => void
}

export const WorkLogMonthSlider = ({ currentDate, onMonthChange }: WorkLogMonthSliderProps): ReactElement => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const currentMonth = currentDate.getMonth()

  const handleMonthChange = (selectedMonth: number, selectedYear?: number): void => {
    onMonthChange(selectedMonth, selectedYear)

    if (isOpen) {
      onClose()
    }
  }

  const isMonthActive = (monthNumber: number): boolean => currentMonth === monthNumber

  const handleSelectPreviousMonth = (): void => {
    const selectedMonth = currentMonth === 0 ? TOTAL_MONTH_NUMBER : currentMonth
    let selectedYear = currentDate.getFullYear()
    if (currentMonth === FIRST_MONTH && selectedMonth === TOTAL_MONTH_NUMBER) {
      selectedYear -= 1
    }
    handleMonthChange(selectedMonth - 1, selectedYear)
  }

  const handleSelectNextMonth = (): void => {
    const selectedMonth = TOTAL_MONTH_NUMBER - currentMonth === 1 ? 0 : currentMonth + 1
    let selectedYear = currentDate.getFullYear()
    if (currentMonth + 1 === TOTAL_MONTH_NUMBER && selectedMonth === FIRST_MONTH) {
      selectedYear += 1
    }
    handleMonthChange(selectedMonth, selectedYear)
  }

  return (
    <Box position="relative" width="220px">
      <ButtonGroup width="100%" size="sm" isAttached variant="outline">
        <IconButton aria-label="" icon={<FaAngleLeft />} onClick={handleSelectPreviousMonth} />
        <Button width="200px" onClick={onOpen}>
          {getMonthName(currentMonth, 'LLLL')}
        </Button>
        <IconButton aria-label="" icon={<FaAngleRight />} onClick={handleSelectNextMonth} />
      </ButtonGroup>
      <Overlay isOpen={isOpen} onClose={onClose} />

      {isOpen && (
        <Box
          position="absolute"
          top="calc(100% + 1rem)"
          left="0"
          width="100%"
          bgColor="#1A202C"
          p={4}
          zIndex={101}
          borderRadius={4}
        >
          <Grid templateColumns="repeat(3, 1fr)" gap={1}>
            {Array.from({ length: TOTAL_MONTH_NUMBER }).map((_, monthNumber) => (
              <GridItem key={monthNumber} w="100%" h="10" display="flex" justifyContent="center">
                <Button
                  variant="ghost"
                  fontSize="sm"
                  height="100%"
                  width="100%"
                  padding={0}
                  bgColor={isMonthActive(monthNumber) ? 'teal.900' : undefined}
                  onClick={() => handleMonthChange(monthNumber)}
                >
                  {getMonthName(monthNumber)}
                </Button>
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}
    </Box>
  )
}
